(function ($, window, document, undefined) {

  'use strict';
  //var waitMax = 3000;
  //var waitMin = 1000;
  var waitMax = 5000;
  var waitMin = 3000;
  var targetFlag = 0;


//     .___       __
//   __| _/____ _/  |______
//  / __ |\__  \\   __\__  \
// / /_/ | / __ \|  |  / __ \_
// \____ |(____  /__| (____  /
//      \/     \/          \/

  var shuffledPokemonGifs = [];
  var pokemonGifs = [
    //7
    './assets/img/pokemon/BUNNY_FLOWER_CRAFT.gif',
    './assets/img/pokemon/STEAL_PIKA.gif',
    './assets/img/pokemon/HEALTH_HUG.gif',
    './assets/img/pokemon/LOVE_SNAKE.gif',
    './assets/img/pokemon/ROYAL_TRAINER.gif',
    './assets/img/pokemon/HAPPY-ASH.gif',

    //14
    './assets/img/pokemon/CRY-BIRD.gif',
    './assets/img/pokemon/ROPE_BONDAGE.gif',
    './assets/img/pokemon/BROCK_MAID.gif',
    './assets/img/pokemon/BROCK_RIDE.gif',
    './assets/img/pokemon/MOUNTAIN_RUNNER.gif',
    './assets/img/pokemon/LION-LICK.gif',
    './assets/img/pokemon/JUMP-WORM.gif',

    //21
    './assets/img/pokemon/MISTY_OWL.gif',
    './assets/img/pokemon/PURPLE_SCARE.gif',
    './assets/img/pokemon/PINK_CHEERS.gif',
    './assets/img/pokemon/PURPLE_FAN.gif',
    './assets/img/pokemon/DUAL_BALL_TRAINER.gif',
    './assets/img/pokemon/PIKA_DIVE.gif',
    './assets/img/pokemon/ELECTRIC_ATTACK.gif',


    //28 ash hat toss
    //'./assets/img/pokemon/G9qfCvxlwGAaQ.gif',
    './assets/img/pokemon/ELEPHANT_PIKA_PARRY.gif',
    './assets/img/pokemon/LANTERN_ATTACK.gif',
    './assets/img/pokemon/3000762-snorlax.gif',
    './assets/img/pokemon/HEAD_SCRATCHERS.gif',
    './assets/img/pokemon/ROCKET_TOTTER.gif',
    './assets/img/pokemon/ELECTRIC_KISS.gif',
    './assets/img/pokemon/steal_gold_0fb94b5238b785967da7765e33a7d8aa.gif',

    //35
    './assets/img/pokemon/POOL-PIKU.gif',
    './assets/img/pokemon/PURPLE_YAWN.gif',
    './assets/img/pokemon/muk.gif',
    './assets/img/pokemon/PURPLE_PUFFER.gif',
    './assets/img/pokemon/GUITAR_TRAINER.gif',
    './assets/img/pokemon/GREEN_LANDER.gif',
    './assets/img/pokemon/SLASHY_TRAINER.gif',

    //42
    './assets/img/pokemon/MEOW_DJ.gif',
    './assets/img/pokemon/MISTY_BATH.gif',
    './assets/img/pokemon/MAGICARP_JUGGLE.gif',
    './assets/img/pokemon/MISTY_DESK.gif',
    './assets/img/pokemon/BREAST_TEASE.gif',
    './assets/img/pokemon/WATER_RIDERS.gif',
    './assets/img/pokemon/BLASTASAUR_ATTACK.gif',

    /*
    './assets/img/pokemon/SHIFTY_EYES.gif',
    './assets/img/pokemon/MISTY_SPIN.gif',
    './assets/img/pokemon/WOOD_KNOCKOUT.gif',
    './assets/img/pokemon/MISTY_PSYDUCK.gif',
    './assets/img/pokemon/MEOW_CANNON.gif',
    './assets/img/pokemon/32d1Km2.gif',
    './assets/img/pokemon/CAT_ATTACK.gif',
    './assets/img/pokemon/Pokemon-beneficios.gif',
    './assets/img/pokemon/MEOW_BLUE_SKY.gif',
    './assets/img/pokemon/CHAT_BUBBLES.gif',
    './assets/img/pokemon/TEAM_WALK.gif',
    './assets/img/pokemon/MISTY_DIVE.gif',
    './assets/img/pokemon/DUALING_ATTACK.gif',
    './assets/img/pokemon/PURPLE_SLUDGE.gif',
    './assets/img/pokemon/EVIL_FLOATERS.gif',
    './assets/img/pokemon/MISTY_VICTORY.gif',
    './assets/img/pokemon/PURPLE_BATTIES.gif',
    './assets/img/pokemon/HAT_SWITCH.gif',
    */
    //'./assets/img/pokemon/MISTY_MERMAID.gif',
    //'./assets/img/pokemon/BROCK_DRAG.gif',
    //'./assets/img/pokemon/SAD_BEAN.gif',
    //'./assets/img/pokemon/MISTY_CHEER.gif',
    //'./assets/img/pokemon/BLACK_WHITE_WALK.gif',
    //'./assets/img/pokemon/MISTY_TOSS.gif',
    //'./assets/img/pokemon/STARFISH_TEARS.gif',
    //'./assets/img/pokemon/3D_MULTIPLE.gif',
    //'./assets/img/pokemon/DIGGING_CRABS.gif',
    //'./assets/img/pokemon/ATTACK_STOMACH_ACHE_ASH.gif',
    //'./assets/img/pokemon/LEAF_ATTACK.gif',
    //'./assets/img/pokemon/FLOWER_DRAGON_SCENE.gif',
    //'./assets/img/pokemon/3D_FLOWER_KWEEN.gif',
    //'./assets/img/pokemon/WORM_ATTACK_LAMP.gif',
    //'./assets/img/pokemon/WATER_OTTER_ATTACK.gif',
    //'./assets/img/pokemon/LAMB_ATTACK.gif',
    //'./assets/img/pokemon/FIRE-ASH.gif',

    './assets/img/pokemon/PIKA_KISS.gif',
    './assets/img/pokemon/ROCKET_GIGGLE.gif',
    './assets/img/pokemon/meowth-dj-tumblr_static_77r4k7kselgk00cgcc40kc4o0.gif',
    //pat on the back
    './assets/img/pokemon/61c1e2f1e3cbc68731c06940a8cac3586b9bd3a11429e5f89da9810d171380bd_1.gif'
  ];
  function getSemiRandomPokemon(){
    if(shuffledPokemonGifs.length===0){
      shuffledPokemonGifs = [].concat(pokemonGifs);
      shuffledPokemonGifs = shuffledPokemonGifs.shuffle();
    }
    return shuffledPokemonGifs.pop();
  }

  var shuffledQuotes = [];
  var quotes = [
    //['we must let go of the life we have planned','so as to accept the one that is waitin for us'],
    //['simply be the quality','you seek in others'],
    //['life is like monkey bars','you have to let go if you want to move on'],
    //['the greatest mistake you can make in life','is to continually be afraid you will make one'],
    //['he is richest who is content with the least','for content is the wealth of nature'],
    //['all matter in the cosmos is subject to the same cyclic laws','including the lives of civilizations and individuals'],
    //['the best preperation for good work tomorrow','is to do good work today'],
    //['the aim of life is to live','and to live means to be aware','joyously drunkenly serenely devinely aware'],
    //['do not dwell in the past','do not dream of the future','concentrate the mind on the present moment'],
    //['success is not final','failure is not fatal','it is the courage to continue that counts'],
    //['taking time to live life','will only inspire your work'],
    //['perfection is not of the world','do your best and forget the rest'],
    //['in the end its not the years in your life that count','its the life in your years'],
    //['think positive and','positive things will happen'],
    /*
    ['no matter how far youve gone', 'on the wrong road','you can still turn around'],
    ['stop wishing for it','and start working for it'],
    ['nobody can go back and start a new beginning','but anyone can start today and make a new ending'],
    ['dont be afraid to fail','be afraid not to try'],
    ['love the life you live','live the life you love'],
    ['we tend to forget that happiness doesnt', 'come as a result of getting something we dont have','but of appreciating what we do have'],
    ['seek freedom and become captive of your desires','seek discipline and find your liberty'],
    ['operating on intuition means not knowing','that you actually know you are doing'],
    ['when you say yes to others','make sure you are not saying no to yourself'],
    ['happiness is not something you postpone for the future','it is something you design for the present'],
    ['no act of kindness','no matter how small','is ever wasted'],
    ['our greatest glory is not in never falling','but in rising every time we fall'],
    ['life is not fair','get used to it'],
    ['mistakes are proof','that youre trying'],
    */
    ['life does not have to be perfect','to be wonderful'],
    ['the only place success comes before work','is in the dictionary'],
    ['surround yourself with those who bring out the best in you','not the stress in you'],
    ['when you stop chasing the wrong things','you give the right things a chance to catch you'],
    ['the less you respond to negative people','the more peaceful your life will become'],
    ['beauty attracts the eye','but personality captures the heart'],
    ['is is not how much we have','but how much we enjoy','that makes hapiness'],
    ['remember that you are absolutely unique','just like everyone else'],
    ['nine tenths of people were created','so you would want to be with the other tenth'],
    ['never let the things you want','make you forget the things you have'],
    ['sometimes you dont know the value of a moment','until it becomes a memory'],
    ['if today was perfect','there would be no need for tomorrow'],
    ['youll never leave where you are','until you decide where youd rather be'],
    ['a good listener is','usually thinking about something else'],
    ['fear is temporary','regret can last forever'],
    ['nothing is permanent in this wicked world','not even our troubles'],
    ['the feisty ones are more fun to tame','than the obedient ones'],
    ['beauty begins the moment','you decide to be yourself'],
    ['even if youre on the right track','youll still get run over if you just sit there'],
    ['you are not a drop in the ocean','you are the entire ocean in a drop'],
    ['stop being afraid of what could go wrong','and think of what could go right'],
    ['our days are happier when we give people a bit of our heart','rather than a piece of our mind'],
    ['you can suffer the pain of change','or suffer remaining the way you are'],
    ['dont let the world change your smile','make your smile change the world'],
    ['a simple hello','could lead to a million things'],
    ['if you desire to make a difference in the world','you must be different from the world'],
    ['love people for who they are','instead of judging them for who they are not'],
    ['make the rest of your life','the best of your life'],
    ['keep your face always toward the sunshine','and shadows will fall behind you'],
    ['look for something positive in each day','even if some days you have to look a little harder'],
    ['be yourself','everyone else is taken'],
    ['forgiveness does not change the past','but it does enlarge the future'],
    ['you cant live a positive life','with a negative mind'],
    ['people may hear your words','but they feel your attitude'],
    ['positivity is a choice'],
    ['the sun too shines into cesspools','and is not polluted'],
    ['you never look good','trying to make someone else look bad'],
    ['nothing ever goes away','until it teaches us what we need to know'],
    ['the best way to predict the future','is to create it'],
    ['with every rising of the sun','think of your life as just begun'],
    ['wherever you are','be all there'],
    ['dreams dont work','unless you do'],
    ['simplicity is the ultimate sophistication'],
    ['challenges are what make life interesting','overcoming them is what makes life meaningful'],
    ['i am thankful for all those difficult people in my life','they have shown me exactly who i do not want to be'],
    ['the time you enjoy wasting','is not time wasted'],
    ['it&apos;s not who you are that holds you back,','it&apos;s who you think you&apos;re not'],
    ['worrying does not take away tomorrows troubles','it takes away todays peace'],
    ['dont wait for the perfect moment','take the moment and make it perfect'],
    ['don&apos;t be afraid to fail','be afraid not to try'],
    ['when someone tells me no','it doesnt mean i cant do it','it just means i cant do it with them'],
    ['dont let people pull you into their storm','pull them into your peace'],
    ['if you cannot be positive','then at least be quiet'],
    ['instead of searching for your soulmate', 'start searching for your soul, mate.'],
    ['dont worry about those who talk behind your back','theyre behind you for a reason'],
    ['heroism is endurance','for one moment more']
  ];
  function getSemiRandomQuote(){
    if(shuffledQuotes.length===0){
      shuffledQuotes = [].concat(quotes);
      shuffledQuotes = shuffledQuotes.shuffle();
    }
    return shuffledQuotes.pop();
  }


// .__           .__
// |  |__   ____ |  | ______   ___________  ______
// |  |  \_/ __ \|  | \____ \_/ __ \_  __ \/  ___/
// |   Y  \  ___/|  |_|  |_> >  ___/|  | \/\___ \
// |___|  /\___  >____/   __/ \___  >__|  /____  >
//      \/     \/     |__|        \/           \/

  /**
  * Returns a random integer between min and max
  * Using Math.round() will give you a non-uniform distribution!
  */
  function getRandomInt (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  Array.prototype.shuffle = function () {
    for (var i = this.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var tmp = this[i];
      this[i] = this[j];
      this[j] = tmp;
    }
    return this;
  };


//                .__         ___.                .__
//   _____ _____  |__| ____   \_ |______________  |__| ____   ______
//  /     \\__  \ |  |/    \   | __ \_  __ \__  \ |  |/    \ /  ___/
// |  Y Y  \/ __ \|  |   |  \  | \_\ \  | \// __ \|  |   |  \\___ \
// |__|_|  (____  /__|___|  /  |___  /__|  (____  /__|___|  /____  >
//       \/     \/        \/       \/           \/        \/     \/

  function displayRandomAnime(){
    var semiRandomPokemon = getSemiRandomPokemon()
    console.log(semiRandomPokemon);
    document.getElementById('animeLoop').style.backgroundImage='url("'+ semiRandomPokemon +'")';
  }

  function displayRandomQuote(){
    var quoteArray = getSemiRandomQuote();
    var quoteString = '<p>';
    quoteArray.forEach( function(s) {
      quoteString = quoteString + s.replace(/ /g, '&nbsp;') + '<br/>';
    } );
    quoteString = quoteString + '</p>'
    //console.log(quoteString);
    document.getElementById('quoteZone').innerHTML = quoteString;
  }

  function changeElement(){
    //determine which element to swap
    //var targetFlag = getRandomInt(0,1);
    if(targetFlag===0){
      targetFlag = 1;
      displayRandomAnime();
    }else{
      targetFlag = 0;
      displayRandomQuote();
    }

    //reset the timer again
    setTimeout(changeElement,getRandomInt(waitMin,waitMax));
  }


  displayRandomAnime();
  displayRandomQuote();
  setTimeout(changeElement,getRandomInt(waitMin,waitMax));


  $(function () {
  });

  //animeLoop

})(jQuery, window, document);
